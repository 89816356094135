import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./App.css";
import { SnackbarProvider } from "notistack";

import Page from "./page/page";
import { SnackbarUtilsConfigurator } from "./page/Common/SnackbarUtils";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function App() {
	return (
		<div>
			<SnackbarProvider preventDuplicate autoHideDuration={5000} maxSnack={5}>
				<SnackbarUtilsConfigurator />
				<CssBaseline />
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Page />
				</MuiPickersUtilsProvider>

			</SnackbarProvider>
			
		</div>
	);
}

export default App;
