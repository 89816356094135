export function formatDate(d){
    //check if date or string
    if (d instanceof Date) {
        //convert to dd/mm/yyyy string
       return `${`0${d.getDate()}`.slice(-2)}/${`0${d.getMonth() + 1}`.slice(-2)}/${d.getFullYear()}`
        
    }

    return d
    
}

export const initalFormState = {
    DOB: null,
    sigDate: null,
    P45: null,
    title:"",
    forename:"",
    lastname:"",
    prefName:"",
    niNum:"",
    gender:"",
    address:"",
    postcode:"",
    country:"",
    hPhone:"",
    mobile:"",
    email:"",
    POB:"",
    passport:"",
    passportIssue:"",
    permit:"",
    permitDetails:"",
    emName:"",
    emRelationship:"",
    emLocation:"",
    emhPhone:"",
    emwPhone:"",
    emmPhone:"",
    emEmail:"",
    emContact:"",
    file:null,
    work:"",
    student:"",
    bank:"",
    sortcode:"",
    accNum:"",
    accName:"",
    sig:"",
    bankCompany:""

}