import React, { useState } from "react";
import TextField from "./Common/TextField"
import { FormControl, FormControlLabel, FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {DropzoneArea} from 'material-ui-dropzone'
import Button from "@material-ui/core/Button";
import DialogBox from "./Dialog"

import {fillPDF} from "../API/API"

import { KeyboardDatePicker } from "@material-ui/pickers";
import { initalFormState } from "./Common/Utils";

const useStyles = makeStyles(theme =>({
    root:{
        padding: theme.spacing(2,5)
    },
    padding: {
        paddingBottom: theme.spacing(1)
    }
}))


export default function Form(props){
    const { setAuth, loading, open, setOpen, formVals, setFormVals } = props;
    const classes = useStyles()    

    const handleClose = e => {
        setOpen(false)
        setAuth(false)
        setFormVals(initalFormState)
    }
    const handleChange = e => {

        const {value, name} = e.target
        setFormVals({...formVals, [name]: value})
    }

    const handleDateChange = (d, name) => {

        setFormVals({...formVals, [name]: d})
    }

    const handleFileChange = (f, name) => {
        const file = f[0]
        setFormVals({...formVals, [name]: file})
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
              encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve(encoded);
          };
          reader.onerror = error => reject(error);
        });
      }

    const submit = async e =>{
        loading(true)
        let vals = formVals
        if(formVals.file){
            const base64File = await getBase64(formVals.file)
            vals ={
                ...vals,
                base64File,
                filetype: formVals.file.type,

            }
        }
        const submitted = await fillPDF(vals)
        loading(false)

        if(submitted){
            setOpen(true)
        }
    }
    return (
        <div className={classes.root}>
            <FormControl fullWidth>
                <Typography variant="h5" component="h6">Personal Details</Typography>
                <TextField
                    label="Title"
                    name="title"
                    value={formVals.title}
                    onChange={handleChange}
                />

                <TextField
                    label="Forename(s)"
                    name="forename"
                    value={formVals.forename}
                    onChange={handleChange}
                />

                <TextField
                    label="Last Name"
                    name="lastname"
                    value={formVals.lastname}
                    onChange={handleChange}
                />
                
                <TextField
                    label="Prefered Name"
                    name="prefName"
                    value={formVals.prefName}
                    onChange={handleChange}
                />


                <KeyboardDatePicker 
                    clearable
                    value={formVals.DOB}
                    onChange={d => handleDateChange(d, "DOB")}
                    format="dd/MM/yyyy"
                    label="Date of Birth"
                    inputVariant="outlined"
                    helperText="DD/MM/YYYY"
                    margin="normal"
                />

                <TextField
                    label="National Insurance No"
                    name="niNum"
                    value={formVals.niNum}
                    onChange={handleChange}
                />

                <TextField
                    label="Gender"
                    name="gender"
                    value={formVals.NINum}
                    onChange={handleChange}
                />

                 <TextField
                    label="Address"
                    name="address"
                    value={formVals.address}
                    onChange={handleChange}
                    multiline
                    rows={4}
                />

                <TextField
                    label="Post Code"
                    name="postcode"
                    value={formVals.postcode}
                    onChange={handleChange}
                />

                <TextField
                    label="Country"
                    name="country"
                    value={formVals.country}
                    onChange={handleChange}
                />

                <TextField
                    label="Home Telephone"
                    name="hPhone"
                    value={formVals.hPhone}
                    onChange={handleChange}
                />

                <TextField
                    label="Mobile"
                    name="mobile"
                    value={formVals.mobile}
                    onChange={handleChange}
                />

                <TextField
                    label="Email"
                    name="email"
                    value={formVals.email}
                    onChange={handleChange}
                />

                <Typography variant="h5" component="h6">Permission To Work In The UK</Typography>
                <TextField
                    label="Place of Birth"
                    name="POB"
                    value={formVals.POB}
                    onChange={handleChange}
                />

                <TextField
                    label="Passport No"
                    name="passport"
                    value={formVals.passport}
                    onChange={handleChange}
                />

                <TextField
                    label="Place of Issue"
                    name="passportIssue"
                    value={formVals.passportIssue}
                    onChange={handleChange}
                />

                <FormControl component="fieldset">
                    <FormLabel component="legend">Do you require a Permit to Work in the UK ?</FormLabel>
                    <RadioGroup name="permit" value={formVals.permit} onChange={handleChange}>
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>

                <TextField
                    label="Please Provide Details if Yes"
                    name="permitDetails"
                    value={formVals.permitDetails}
                    onChange={handleChange}
                    multiline
                    rows={4}
                />

                <Typography variant="h5" component="h6">Emegency Contact Details</Typography>

                <TextField
                    label="Name"
                    name="emName"
                    value={formVals.emName}
                    onChange={handleChange}
                />

                 <TextField
                    label="Relationship to you"
                    name="emRelationship"
                    value={formVals.emRelationship}
                    onChange={handleChange}
                />

                <TextField
                    label="Location"
                    name="emLocation"
                    value={formVals.emLocation}
                    onChange={handleChange}
                />

                 <TextField
                    label="Contact No (Home)"
                    name="emhPhone"
                    value={formVals.emhPhone}
                    onChange={handleChange}
                />

                <TextField
                    label="Contact No (Work)"
                    name="emwPhone"
                    value={formVals.emwPhone}
                    onChange={handleChange}
                />

                <TextField
                    label="Contact No (Mobile)"
                    name="emmPhone"
                    value={formVals.emmPhone}
                    onChange={handleChange}
                />

                <TextField
                    label="Email Address"
                    name="emEmail"
                    value={formVals.emEmail}
                    onChange={handleChange}
                />

                <TextField
                    label="Preferred Method of Contact in an Emergency Situation"
                    name="emContact"
                    value={formVals.emContact}
                    onChange={handleChange}
                />

                <Typography variant="h5" component="h6">Declaration Of Present Circumstances for HMRC Tax Status</Typography>

                <Typography variant="h6" component="h6">Please Upload a Copy of Your P45 Here</Typography>
                <Typography variant="subtitle2" component="h6"><strong>Note: the maximum file size allowed is 5mb</strong></Typography>
                <DropzoneArea 
                    filesLimit={1}
                    showFileNamesInPreview
                    onChange={f => handleFileChange(f, "file")}
                    maxFileSize={5000000}
                />

                <KeyboardDatePicker 
                    clearable
                    value={formVals.P45}
                    onChange={d => handleDateChange(d, "P45")}
                    format="dd/MM/yyyy"
                    label="P45 Date of Issue"
                    inputVariant="outlined"
                    helperText="DD/MM/YYYY"
                    margin="normal"
                />

                <FormControl component="fieldset">
                    <FormLabel component="legend"><strong>Select One</strong></FormLabel>
                    <RadioGroup name="work" value={formVals.work} onChange={handleChange}>
                        <FormControlLabel classes={{root: classes.padding}} value="A" control={<Radio />} label="This is my first job since last 6 April and I have not been receiving taxable Jobseeker's Allowance, Employment and Support Allowance or taxable Incapacity Benefit or a state or occupational pension." />
                        <FormControlLabel value="B" control={<Radio />} label="This is now my only job, but since last 6 April I have had another job, or have received taxable Jobseeker's Allowance, Employment and Support Allowance or taxable Incapacity Benefit. I do not receive a state or occupational pension." />
                        <FormControlLabel value="C" control={<Radio />} label="I have another job or receive a state or occupational pension." />
                    </RadioGroup>
                </FormControl>

                <Typography variant="h6" component="h6">Student Loans</Typography>
                <FormControl component="fieldset">
                    <FormLabel component="legend">If you left a course of UK Higher Education before last 6 April and received your first UK Student Loan instalment on or after 1 September 1998 and you have not fully repaid your Student Loan, select the appropriate box below. (Do not select a box if you are repaying your UK Student Loan by agreement with the UK Student Loans Company to make monthly payments through your bank or building society account.)</FormLabel>
                    <RadioGroup name="student" value={formVals.student} onChange={handleChange}>
                        <FormControlLabel value="no" control={<Radio />} label="No Student Loan" />
                        <FormControlLabel value="p1" control={<Radio />} label="Plan 1" />
                        <FormControlLabel value="p2" control={<Radio />} label="Plan 2" />
                        <FormControlLabel value="post" control={<Radio />} label="Postgraduate" />
                    </RadioGroup>
                </FormControl>

                <Typography variant="h5" component="h6">Bank Details for Payroll and Any Other Employment Related Payments</Typography>

                <FormControl component="fieldset">
                    <FormLabel component="legend">Please select which company you will be employed under</FormLabel>
                    <RadioGroup name="bankCompany" value={formVals.bankCompany} onChange={handleChange}>
                        <FormControlLabel value="uk" control={<Radio />} label="Borderfree Trade Limited (t/a SimplyVAT.com)" />
                        <FormControlLabel value="eu" control={<Radio />} label="Simply VAT (EU) Limited" />
                    </RadioGroup>
                </FormControl>
                {formVals.bankCompany === "uk" && 
                <>
                 <TextField
                    label="Account Name"
                    name="accName"
                    value={formVals.accName}
                    onChange={handleChange}
                />

                <TextField
                    label="Sort Code"
                    name="sortcode"
                    value={formVals.sortcode}
                    onChange={handleChange}
                />

                <TextField
                    label="Account No"
                    name="accNum"
                    value={formVals.accNum}
                    onChange={handleChange}
                />

                <TextField
                    label="Bank Name"
                    name="bank"
                    value={formVals.bank}
                    onChange={handleChange}
                />

                </>
                }
               

               {formVals.bankCompany === "eu" && 
               
               <>

               <TextField
                    label="Account Name"
                    name="accName"
                    value={formVals.accName}
                    onChange={handleChange}
                />


                <TextField
                    label="Account No"
                    name="accNum"
                    value={formVals.accNum}
                    onChange={handleChange}
                />

                <TextField
                    label="Bank Name"
                    name="bank"
                    value={formVals.bank}
                    onChange={handleChange}
                />

                               <TextField
                    label="IBAN"
                    name="iban"
                    value={formVals.iban}
                    onChange={handleChange}
                />

                <TextField
                    label="NSC"
                    name="nsc"
                    value={formVals.nsc}
                    onChange={handleChange}
                />
               
                
                <TextField
                    label="Bank Street Address"
                    name="bankAddress"
                    value={formVals.bankAddress}
                    onChange={handleChange}
                />


                <TextField
                    label="Bank City"
                    name="bankCity"
                    value={formVals.bankCity}
                    onChange={handleChange}
                />

                <TextField
                    label="PPS Number"
                    name="pps"
                    value={formVals.pps}
                    onChange={handleChange}
                />
               
               </>
               
               }

                

               
               

                
                <Typography variant="subtitle2" component="h6"><strong>Please ensure the Account Name is as shown on your bank statement, as this must match the bank "name check".</strong></Typography>
                
                <Typography variant="h5" component="h6">Signature</Typography>
                <TextField
                    label="Enter Initials for Signature"
                    name="sig"
                    value={formVals.sig}
                    onChange={handleChange}
                />
                <KeyboardDatePicker 
                    clearable
                    value={formVals.sigDate}
                    onChange={d => handleDateChange(d, "sigDate")}
                    format="dd/MM/yyyy"
                    label="Today's Date"
                    inputVariant="outlined"
                    helperText="DD/MM/YYYY"
                    margin="normal"
                    
                />
                <Button variant="contained" color="primary" onClick={submit}>
					Submit
				</Button>
            </FormControl>

            <DialogBox title={"Success!"} text={"Thank you for submitting the new employee form!"} open={open} handleClose={handleClose}/>
        </div>
        
    )
}