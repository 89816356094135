import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';

import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    root:{
        padding: theme.spacing(5, 5),
        display: "flex",
        flex: "1 1 auto",
        alignItems: "center",
        paddingBottom: theme.spacing(3)

    }
}))

export default function TopBar(props){
    const {children} = props
    const classes = useStyles()

    return(
        <Container maxWidth="md">
            <Paper>
                <div className={classes.root}>
                    <Typography component="h1" variant="h4">New Employee Form</Typography>
                    
                </div>
                <Divider />
                {children}
            </Paper>
            
        </Container>
    )
}