import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

export default function DialogBox(props) {
	return (
		<Dialog open={props.open} onClose={props.handleClose}>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.text}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={props.handleClose}>
					Done
				</Button>
			</DialogActions>
		</Dialog>
	);
}
