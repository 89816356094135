import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import hash from 'hash.js'

import {Auth} from "../API/API"

export default function PasswordPage(props) {
	const { setAuth } = props;
	const [pass, setPass] = useState("");
	const [open, setOpen] = useState(true);
    const[error, setError]= useState({error:false, text:""})

    const handleChange = e =>{
        const {value} = e.target
        setPass(value)
    }

    const handleCheck = async (e) =>{
        e.preventDefault()
        const check = await Auth(hash.sha256().update(pass).digest('hex'))
        if(check){
            setOpen(false)
            setError({error:false, text:""})
            setAuth(true)
            
        }else{
            setError({error:true, text:"Incorrect password!"})
        }
        
    }
	return (
		<Dialog open={open} disableBackdropClick disableEscapeKeyDown>
			<DialogTitle id="form-dialog-title">Enter Password</DialogTitle>

			<DialogContent>
				<DialogContentText>
					Please enter the password to access the form
				</DialogContentText>
                <form onSubmit={handleCheck} id="passForm">
                    <TextField
                        label="Password"
                        variant="outlined"
                        margin="normal"
                        name="Password"
                        value={pass}
                        fullWidth
                        onChange={handleChange}
                        type="Password"
                        error={error.error}
                        helperText={error.text}
                    />
                    </form>
				
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary"  form="passForm" onClick={handleCheck}>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
}
