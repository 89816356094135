import request from  "./Request"
import {formatDate} from "../page/Common/Utils"
export async function Auth(key){
    return await request("/auth",{
        method: "Post",
        body: JSON.stringify({key: key})
    })
}

export async function fillPDF(formValues){

    const vals = {
        ...formValues,
        DOB: formatDate(formValues.DOB),
        P45: formatDate(formValues.P45),
        sigDate: formatDate(formValues.sigDate)
    }
    return await request("/submit", {
        method: "Post",
        body: JSON.stringify(vals).replace(/[\\]/g, "\\$&")
    })
}