import React from 'react'
import TextField from '@material-ui/core/TextField';


export default function TextInput(props){
    const {
        label,
        name,
        value,
        onChange,
        ...rest
    } = props

    return (
        <TextField 
            label={label}
            variant="outlined"
            margin="normal"
            name={name}
            value={value}
            onChange={onChange}
            {...rest}
        />
    )
}
