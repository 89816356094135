import React, { useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress"

import TopBar from "./topbar";
import PasswordPage from "./passwordCheck";
import Form from "./form"
import {initalFormState} from "./Common/Utils"

export default function Page(props) {
	const [auth, setAuth] = useState(false);
	const [loading, setLoading] = useState(false)
	const[open, setOpen] = useState(false)
	const [formVals, setFormVals] = useState(initalFormState)

	return (
		<>
			{!auth && <PasswordPage setAuth={setAuth} />}
			{auth && loading && <LinearProgress color="primary" />}
			{auth && !loading && <TopBar>
                <Form setAuth={setAuth} loading={setLoading} open={open} setOpen={setOpen} formVals={formVals} setFormVals={setFormVals}/>
                </TopBar>}
		</>
	);
}
